import React, { lazy } from 'react'

const RecruitmentManagementListing = lazy(() => 
  import('./RecruitmentModule/RecruitmentManagementListing').then(module => ({
    default: module.RecruitmentManagementListing,
  }))
)

const HomeRoutes = [
  // ------------- Recruitment Management -------------- //
  {
    props: { exact: true, path: '/RecruitmentManagementListing' },
    component: <RecruitmentManagementListing />,
  },


]

export default HomeRoutes

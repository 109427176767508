import React, { lazy } from 'react'

// Talent Pool
const TalentPoolMenuListing = lazy(() => 
  import('../RecruitmentModule/TalentPool/TalentPoolMenuListing').then(module => ({
    default: module.TalentPoolMenuListing,
  }))
)

const TalentPoolListing = lazy(() => 
  import('../RecruitmentModule/TalentPool/TalentPoolListing/TalentPoolListing').then(module => ({
    default: module.TalentPoolListing,
  }))
)

const TalentPoolForm = lazy(() =>
  import('../RecruitmentModule/TalentPool/TalentPoolListing/TalentPoolForm').then(module => ({
    default: module.TalentPoolForm,
  }))
)

const TalentPoolView = lazy(() =>
  import('../RecruitmentModule/TalentPool/TalentPoolListing/TalentPoolView').then(module => ({
    default: module.TalentPoolView,
  }))
)

const TalentPoolDetails = lazy(() =>
  import('../RecruitmentModule/TalentPool/TalentPoolListing/TalentPoolDetails').then(module => ({
    default: module.TalentPoolDetails,
  }))
)

const TalentPoolApplication = lazy(() =>
  import('../RecruitmentModule/TalentPool/TalentPoolListing/TalentPoolApplication').then(module => ({
    default: module.TalentPoolApplication,
  }))
)

// External Update
const ExternalUpdateListing = lazy(() =>
  import('../RecruitmentModule/TalentPool/ExternalUpdate/ExternalUpdateListing').then(module => ({
    default: module.ExternalUpdateListing,
  }))
)

const ExternalUpdateView = lazy(() =>
  import('../RecruitmentModule/TalentPool/ExternalUpdate/ExternalUpdateView').then(module => ({
    default: module.ExternalUpdateView,
  }))
)

// Recruitment Requisition
const RecruitmentRequisitionListing = lazy(() =>
  import('../RecruitmentModule/RecruitmentRequisition/RecruitmentRequisitionListing').then(module => ({
    default: module.RecruitmentRequisitionListing,
  }))
)

const RecruitmentRequisitionForm = lazy(() =>
  import('../RecruitmentModule/RecruitmentRequisition/RecruitmentRequisitionForm').then(module => ({
    default: module.RecruitmentRequisitionForm,
  }))
)

const RecruitmentRequisitionView = lazy(() =>
  import('../RecruitmentModule/RecruitmentRequisition/RecruitmentRequisitionView').then(module => ({
    default: module.RecruitmentRequisitionView,
  }))
)

// Job Vacancy Management
const JobVacancyManagement = lazy(() =>
  import('../RecruitmentModule/JobVacancyManagement/JobVacancyManagement').then(module => ({
    default: module.JobVacancyManagement,
  }))
)

const JobVacancyListing = lazy(() =>
  import('../RecruitmentModule/JobVacancyManagement/JobVacancy/JobVacancyListing').then(module => ({
    default: module.JobVacancyListing,
  }))
)

const JobVacancyForm = lazy(() =>
  import('../RecruitmentModule/JobVacancyManagement/JobVacancy/JobVacancyForm').then(module => ({
    default: module.JobVacancyForm,
  }))
)

const JobVacancyView = lazy(() =>
  import('../RecruitmentModule/JobVacancyManagement/JobVacancy/JobVacancyView').then(module => ({
    default: module.JobVacancyView,
  }))
)

//Job Posting
const JobPostingListing = lazy(() =>
  import('../RecruitmentModule/JobVacancyManagement/JobPosting/JobPostingListing').then(module => ({
    default: module.JobPostingListing,
  }))
)

const JobPostingForm = lazy(() =>
  import('../RecruitmentModule/JobVacancyManagement/JobPosting/JobPostingForm').then(module => ({
    default: module.JobPostingForm,
  }))
)

const JobPostingView = lazy(() =>
  import('../RecruitmentModule/JobVacancyManagement/JobPosting/JobPostingView').then(module => ({
    default: module.JobPostingView,
  }))
)

const JobPostingApplication = lazy(() =>
  import('../RecruitmentModule/JobVacancyManagement/JobPosting/JobPostingApplication').then(module => ({
    default: module.JobPostingApplication,
  }))
)

// Candidate Management
const CandidateManagementListing = lazy(() =>
  import('../RecruitmentModule/JobVacancyManagement/CandidateManagement/CandidateManagementListing').then(module => ({
    default: module.CandidateManagementListing,
  }))
)

const recruitmentRoutes = [
  // Talent Pool
  {
    props: { exact: true, path: '/RecruitmentManagement/TalentPool' },
    component: <TalentPoolMenuListing />,
  },

  {
    props: { exact: true, path: '/TalentPool/TalentPoolListing' },
    component: <TalentPoolListing />,
  },

  {
    props: { exact: true, path: '/TalentPool/TalentPoolForm/New' },
    component: <TalentPoolForm mode="add" />,
  },

  {
    props: { exact: true, path: '/TalentPool/TalentPoolForm/Edit' },
    component: <TalentPoolForm mode="edit" />,
  },

  {
    props: { exact: true, path: '/TalentPool/TalentPoolView' },
    component: <TalentPoolView />,
  },

  {
    props: { exact: true, path: '/TalentPool/TalentPoolView/TalentPoolDetails' },
    component: <TalentPoolDetails />,
  },

  {
    props: { exact: true, path: '/TalentPool/TalentPoolApplication' },
    component: <TalentPoolApplication />,
  },

  // External Update
  {
    props: { exact: true, path: '/ExternalUpdate/ExternalUpdateListing' },
    component: <ExternalUpdateListing />,
  },

  {
    props: { exact: true, path: '/ExternalUpdate/ExternalUpdateView' },
    component: <ExternalUpdateView />,
  },

  // Recruitment Requisition
  {
    props: { exact: true, path: '/RecruitmentRequisition/RecruitmentRequisitionListing' },
    component: <RecruitmentRequisitionListing />,
  },

  {
    props: { exact: true, path: '/RecruitmentRequisition/RecruitmentRequisitionForm/New' },
    component: <RecruitmentRequisitionForm mode="add" />,
  },

  {
    props: { exact: true, path: '/RecruitmentRequisition/RecruitmentRequisitionForm/Edit' },
    component: <RecruitmentRequisitionForm mode="edit" />,
  },

  {
    props: { exact: true, path: '/RecruitmentRequisition/RecruitmentRequisitionView/Submitted' },
    component: <RecruitmentRequisitionView mode="submitted" />,
  },

  {
    props: { exact: true, path: '/RecruitmentRequisition/RecruitmentRequisitionView/Approved' },
    component: <RecruitmentRequisitionView mode="approved" />,
  },

  {
    props: { exact: true, path: '/RecruitmentRequisition/RecruitmentRequisitionView/Rejected' },
    component: <RecruitmentRequisitionView mode="rejected" />,
  },

  // Job Vacancy Management
  {
    props: { exact: true, path: '/RecruitmentManagement/JobVacancyManagement' },
    component: <JobVacancyManagement />,
  },

  {
    props: { exact: true, path: '/JobVacancy/JobVacancyListing' },
    component: <JobVacancyListing />,
  },

  {
    props: { exact: true, path: '/JobVacancy/JobVacancyForm/New' },
    component: <JobVacancyForm mode="add" />,
  },

  {
    props: { exact: true, path: '/JobVacancy/JobVacancyForm/Edit' },
    component: <JobVacancyForm mode="edit" />,
  },

  {
    props: { exact: true, path: '/JobVacancy/JobVacancyView' },
    component: <JobVacancyView />,
  },

  // Job Posting
  {
    props: { exact: true, path: '/JobPosting/JobPostingListing' },
    component: <JobPostingListing />,
  },

  {
    props: { exact: true, path: '/JobPosting/JobPostingForm/New' },
    component: <JobPostingForm mode="add" />,
  },

  {
    props: { exact: true, path: '/JobPosting/JobPostingForm/Edit' },
    component: <JobPostingForm mode="edit" />,
  },

  {
    props: { exact: true, path: '/JobPosting/JobPostingView/View' },
    component: <JobPostingView mode="view" />,
  },

  {
    props: { exact: true, path: '/JobPosting/JobPostingView/Publish' },
    component: <JobPostingView mode="publish" />,
  },

  {
    props: { exact: true, path: '/JobPosting/JobPostingApplication' },
    component: <JobPostingApplication />,
  },

  // Candidate Management
  {
    props: { exact: true, path: '/CandidateManagement/CandidateManagementListing' },
    component: <CandidateManagementListing />,
  },
]

export default recruitmentRoutes